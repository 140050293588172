<template>
  <div class="flex items-center">
    <div v-for="jobboard of jobboards" :key="jobboard" class="q-ma-xs q-pa-none bd-2 bd-grey-3 bg-white br-8 flex items-center justify-center" :style="`width:122px;height:66px;${ blurred ? 'opacity: 0.3;': '' }`">
      <img :src="cdnImg($t({ id:`images.jobboard${jobboard}` }))" loading="lazy" style="max-width:115px;max-height:60px;">
      <ActionTooltip :str="jobboard" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    blurred: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      jobboards: ['Indeed', 'LinkedIn', 'Apec', 'FranceTravail', 'Talent', 'Jobijoba', 'GoogleJobs', 'OptionCarriere', 'Glassdoor', 'Adzuna', 'Jooble', 'JobRapido', 'EmploiLibre', 'AllJobs', 'LEtudiant', 'Wizbii', 'EngagementJeunes', 'LeFigaroEmploi', 'PMEJob'],
    }
  }
}
</script>
