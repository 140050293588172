<template>
  <div ref="SideBySideForm" class="bg-gradient">
    <div v-if="ready && !showEndStepProp && !loading" class="row" style="height:calc(100vh - 50px)">
      <!-- Form -->
      <div class="col bg-gradient">
        <AccountCard icon="uil:file-question-alt" title="Formulaire" style="max-height:calc(90vh)">
          <template #header-right>
            <HCButton v-if="!showEndStepProp" :label="savedSignal ? 'notification.saved' : formStructure.preview.quitButton" :color="savedSignal ? 'positive' : 'primary'" icon="uil:save" @click="saveAndQuit($_.get(formStructure, 'returnRoute', 'dashboard'))" />

            <QBtn round color="secondary" size="sm" class="q-ml-xs" icon="uil:sync" flat @click="$emit('formEvent', { method: 'refresh' })">
              <ActionTooltip str="Recharger" />
            </QBtn>
            <QBtn round color="primary" size="sm" class="q-ml-xs" :icon="`uil:${finalPreview ? 'eye-slash' : 'eye'}`" flat @click="finalPreview = !finalPreview">
              <ActionTooltip :str="`${finalPreview ? 'Masquer' : 'Afficher'} l'aperçu`" />
            </QBtn>
          </template>

          <div :style="stl">
            <QStepper
              id="sideBySideStepper"
              v-model="stepperIndex"
              vertical
              color="primary"
              animated
              :header-nav="!$_.isNil(entity.id)"
              @update:modelValue="goToStep(stepperIndex, true)"
            >
              <QStep
                v-for="step of steps"
                :key="step.index"
                :name="step.index"
                :caption="step.optional ? 'Facultatif' : undefined"
                :title="$t({ id: step.label })"
                :icon="step.icon"
                :done-icon="step.icon"
                :color="stepColor(step)"
                :done="currentStep.index > step.index"
              >
                <div v-if="step.helpString" class="row flex justify-end">
                  <HelpToolip :title="step.label" :path="step.helpString" styles="min-width:60%;" />
                </div>
                <AppContent v-if="step.hint" :path="step.hint" class="text-caption text-grey-4" />
                <component
                  :is="step.component"
                  :ref="`linkedComponent${step.index}`"
                  class="q-mt-sm"
                  :step="step"
                  :form-object="entity"
                  :readonly="step.readonly ?? undefined"
                  @stepResult="stepResult($event)"
                />
                <QStepperNavigation class="text-center q-gutter-x-sm">
                  <HCBigButton
                    v-if="step.index !== 0"
                    color="accent"
                    label="prompt.previous_button"
                    @click="saveStep({ stepIncrement: -1 })"
                  />
                  <HCBigButton
                    :disable="!canGoNext"
                    label="prompt.next_button"
                    @click="saveStep({ stepIncrement: 1 })"
                  />
                </QStepperNavigation>
              </QStep>
            </QStepper>
          </div>
        </AccountCard>
      </div>

      <!-- Preview -->
      <component :is="$q.screen.lt.md ? 'QDialog' : 'div'" v-if="finalPreview" v-model="finalPreview" maximized class="col-5 bg-gradient" transition-show="fade" transition-hide="fade">
        <AccountCard icon="uil:eye" title="Aperçu" class="fit overflow-auto">
          <template #header-right>
            <HCButton v-close-popup is-close @hide="finalPreview = false" />
          </template>
          <div>
            <slot v-if="entity && formStructure.preview" name="preview" height="calc(80vh)">
              <QCardSection>
                <component
                  :is="formStructure.preview.component"
                  v-if="entity && formStructure.preview"
                  :steps="steps"
                  show-full
                  :user="entity"
                  :asset="entity"
                  :entity="entity"
                  :form-object="entity"
                  :offer="$_.get(formStructure, 'preview.props.offer', null)"
                  :company="$_.get(formStructure, 'preview.props.company', null)"
                  height="calc(80vh)"
                  @goToStep="openForm($event)"
                />
              </QCardSection>
            </slot>
          </div>
        </AccountCard>
      </component>
    </div>

    <!-- showEndStepProp -->
    <div v-else-if="showEndStepProp && formStructure.endStep" class="row bg-gradient" style="height:calc(100vh - 50px)">
      <div class="col flex column items-center justify-center q-my-xl">
        <img :src="cdnImg($t({ id:`images.${formStructure.endStep.image}` }), { height: 160 })" loading="lazy" style="height:160px;">
        <AppContent :path="formStructure.endStep.title" class="text-h2 text-grey-7" />
        <AppContent :path="formStructure.endStep.subtitle" class="text-body2 text-grey-6 text-center" />
        <QSpinnerPuff
          v-if="$_.get(formStructure, 'endStep.hasLoader', false)"
          color="dark"
          size="50px"
          class="q-mt-md"
          :thickness="5"
        />
        <div class="flex items-center q-mt-lg q-gutter-x-lg">
          <div v-for="(button, i) in formStructure.endStep.buttons" :key="i" class="hc-link anchor-text--reset">
            <HCBigButton
              :color="button.color"
              :label="$t({ id: button.label })"
              :target="button.target ? button.target : '_self'"
              :href="typeof button.route === 'string' ? button.route : undefined"
              :to="typeof button.route !== 'string' ? button.route : undefined"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import BuilderMixins from 'hc-core/mixins/builder.js'
import { deepKeys, useSavedSignal, stepResultExtractor } from 'hc-core/composables/misc.js'
const CompanyAttributeSelector = defineAsyncComponent(() => import('hc-core/components/inputs/company-attribute-selector/CompanyAttributeSelectorv2'))

// Components
const TextInput = defineAsyncComponent(() => import('hc-core/components/forms/text-input'))
const DateSelect = defineAsyncComponent(() => import('hc-core/components/forms/date-select'))
const ChipsPicker = defineAsyncComponent(() => import('hc-core/components/forms/chips-picker'))
const SelectInput = defineAsyncComponent(() => import('hc-core/components/forms/select-input'))
const SliderInput = defineAsyncComponent(() => import('hc-core/components/forms/slider-input'))
const OptionsInput = defineAsyncComponent(() => import('hc-core/components/forms/options-input'))
const CheckboxInput = defineAsyncComponent(() => import('hc-core/components/forms/checkbox-input'))
const ImageCropperUploader = defineAsyncComponent(() => import('hc-core/components/files/image-cropper-uploader'))
const OfferLocation = defineAsyncComponent(() => import('hc-core/components/forms/offer-location'))
const OfferDate = defineAsyncComponent(() => import('hc-core/components/forms/offer-date'))
const OfferWage = defineAsyncComponent(() => import('hc-core/components/forms/offer-wage'))
const OfferDescription = defineAsyncComponent(() => import('hc-core/components/forms/offer-description'))
const OfferReview = defineAsyncComponent(() => import('hc-core/components/forms/offer-review'))
const OfferCategory = defineAsyncComponent(() => import('hc-core/components/forms/offer-category'))
const MultipleItems = defineAsyncComponent(() => import('hc-core/components/forms/multiple-items'))
const LocationsInput = defineAsyncComponent(() => import('hc-core/components/forms/locations-input'))
const ItemsArrayInput = defineAsyncComponent(() => import('hc-core/components/forms/items-array-input'))
const ISOInput = defineAsyncComponent(() => import('hc-core/components/forms/iso-input'))
const PlacesAutocomplete = defineAsyncComponent(() => import('hc-core/components/inputs/places-autocomplete'))
const ToolsPicker = defineAsyncComponent(() => import('hc-core/components/forms/tools-picker'))
const AvatarInput = defineAsyncComponent(() => import('components/forms/avatar-input'))
const OfferTitle = defineAsyncComponent(() => import('hc-core/components/forms/offer-title'))
const HelpToolip = defineAsyncComponent(() => import('hc-core/components/tooltips/help-tooltip'))
const ProfileJobSkills = defineAsyncComponent(() => import('hc-core/components/forms/profile-job-skills'))
const PhoneInput = defineAsyncComponent(() => import('hc-core/components/forms/phone-input'))

// Builds
const OfferBuild = defineAsyncComponent(() => import('hc-core/components/builds/offer-build'))
const GenericBuild = defineAsyncComponent(() => import('hc-core/components/builds/generic-build'))
const ApplicantBuild = defineAsyncComponent(() => import('hc-core/components/builds/applicant-build'))
const TestimonialCard = defineAsyncComponent(() => import('hc-core/components/cards/testimonial-card'))

export default {
  components: {
    CompanyAttributeSelector,

    // Components
    TextInput,
    DateSelect,
    ChipsPicker,
    SelectInput,
    SliderInput,
    OptionsInput,
    CheckboxInput,
    ImageCropperUploader,
    OfferLocation,
    OfferDate,
    OfferWage,
    OfferDescription,
    OfferReview,
    OfferCategory,
    MultipleItems,
    LocationsInput,
    ItemsArrayInput,
    ISOInput,
    PlacesAutocomplete,
    ToolsPicker,
    AvatarInput,
    OfferTitle,
    HelpToolip,
    ProfileJobSkills,
    PhoneInput,

    // builds
    OfferBuild,
    GenericBuild,
    ApplicantBuild,
    TestimonialCard,
  },
  mixins: [BuilderMixins],
  props: {
    showEndStepProp: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array,
      default: () => []
    },
    entityProp: {
      type: Object,
      default: () => {}
    },
    formStructure: {
      type: Object,
      default: () => {}
    },
  },
  emits: ['formEvent', 'refresh'],
  data () {
    return {
      // Specific
      stepperIndex: 0,
      stl: 'max-height: calc(95vh - 64px); overflow: auto',

      loading: false,
      ready: false,
      savedSignal: false,
      currentStep: null,
      finalPreview: true,
      entity: {},
      stepData: {},
      importDialog: false,

      // FormStepCard
      canGoNext: false,
      stepValue: null,
    }
  },
  computed: {
    missingFields () {
      return this.steps.reduce((acc, step) => {
        const toCheck = this.$_.get(step, 'multipleItemsData', [step])
        const forThisStep = toCheck.reduce((sAcc, stepOrItem) => {
          return (
            this.$_.get(this.entity, stepOrItem.field, false) ||
            (Array.isArray(this.$_.get(this.entity, stepOrItem.field, false)) && this.$_.get(this.entity, `${stepOrItem.field}[0]`, false))
          ) && sAcc
        }, true)
        return forThisStep ? acc : acc.concat(step)
      }, [])
    },
    computedEntityBuilder () {
      // We use a computed so that not recomputed in display scrolling - better perf
      return this.entityBuilder({ entity: this.entityProp, getAll: true })
    }
  },
  watch: {
    entityProp: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        try {
          if (val && oldVal === undefined) this.ready = false
          if (val) {
            this.loading = true
            this.entity = this.$_.cloneDeep(this.entityProp)
            if (!this.$_.isEqual(val, oldVal) && oldVal) this.useSavedSignal()
          }
        } catch (e) {
          this.useLogger(e)
        } finally {
          this.$nextTick(() => {
            setTimeout(() => {
              this.loading = false
              if (val || !this.$route.params.id) this.ready = true
            }, 350)
          })
        }
      }
    },
  },
  mounted () {
    // Using slug should only be done a first load, too much hazardous after
    if (this.$route.params.slug) this.goToStep(this.steps.find(({ slug }) => slug === this.$route.params.slug).index)
    else if ((!this.$route.params.id || !this.$route.params.slug) && this.steps) this.goToStep(0)
    if (this.$q.screen.lt.md) {
      this.stl = 'max-height: calc(99vh - 59px); overflow: auto'
      this.finalPreview = false
    }
  },
  methods: {
    useSavedSignal,
    async saveAndQuit (routeName) {
      if (this.$refs[`linkedComponent${this.stepperIndex}`][0]) {
        await this.$refs[`linkedComponent${this.stepperIndex}`][0].stepResult()
        this.saveStep({ stepIncrement: 1, noSwitchStep: true })
      }
      this.$nextTick(() => {
        this.$router.push({ name: routeName ?? 'dashboard' })
      })
    },
    handleSwipe (swipe) {
      if (swipe.direction === 'up' && this.currentStep.index > 0) this.goToStep(this.currentStep.index - 1)
      if (swipe.direction === 'down' && this.currentStep.index < this.steps.length) this.goToStep(this.currentStep.index + 1)
    },
    openForm (evt) {
      if (this.finalPreview) this.finalPreview = false
      this.goToStep(this.steps.find((s) => s.index === evt.index).index, evt.forced)
    },
    goToStep (stepIndex, forced = false) {
      if (forced) {
        this.saveStep({ noSwitchStep: true })
        this.$emit('formEvent', { method: 'setShowProps', value: false })
      }
      if (stepIndex > this.steps.length - 1) {
        // Ensure all required fields are filled
        const opt = this.missingFields.filter(f => f.optional).length
        const req = this.missingFields.filter(f => !f.optional).length

        if (this.missingFields.length) {
          this.$q.dialog({
            title: 'Etapes manquantes',
            message: this.$t({ id: 'form.missing_steps' }, { req, opt }),
            cancel: { label: this.$t({ id: 'prompt.edit_button' }), size: 'md', color: 'dark' },
            ok: { label: this.$t({ id: 'prompt.continue_button' }), size: 'md', color: 'primary', disabled: req },
            persistent: true
          }).onOk(async () => {
            this.$emit('formEvent', { method: 'callback', value: this.$_.merge(this.stepData, this.$_.get(this.formStructure, 'endStep.callbackData', {})) })
          })
        } else {
          this.$emit('formEvent', { method: 'callback', value: this.$_.merge(this.stepData, this.$_.get(this.formStructure, 'endStep.callbackData', {})) })
        }
      } else {
        this.currentStep = this.steps.find(({ index }) => index === stepIndex)
        this.stepperIndex = this.currentStep.index
        if (this.entity.id && this.currentStep && this.currentStep.slug !== this.$route.params.slug) {
          this.$router.push({ name: this.$route.name, params: { slug: this.currentStep.slug, id: this.entity.id }, query: { model: this.$route.query.model ?? undefined } })
        }
      }
      this.stepData = {}
    },
    saveStep ({ stepIncrement = 1, noSwitchStep = false }) {
      try {
        this.loading = true
        // Ensure default fields are here
        this.$_.get(this.formStructure, 'defaultFields', []).forEach((item, i) => {
          if (!this.$_.has(this.entity, item.path) && !this.$_.has(this.stepData, item.path)) this.$_.set(this.stepData, item.path, item.value)
        })
        // Only emit if keys were modified
        const modified = stepResultExtractor(this.stepData, this.entityProp)
        if (Object.keys(modified).length > 0) {
          this.$emit('formEvent', { method: 'saveStep', value: modified, entityId: this.entity.id })
          this.useSavedSignal()
        }
        // Then proceed to next step
        if (!noSwitchStep && (this.stepData || this.$_.get(this.currentStep, 'optional', false) || stepIncrement === -1)) this.goToStep(this.currentStep.index + stepIncrement)
      } catch (e) {
        this.useLogger(e)
      } finally { this.loading = false }
    },
    stepColor (step) {
      if (this.$_.get(this.currentStep, 'index', 0) === step.index) return 'primary'
      else if (this.missingFields.includes(step) && step.index < this.currentStep.index) return 'warning'
      else if (!this.missingFields.includes(step)) return 'secondary'
      else return 'grey-4'
    },

    deleteFields () {
      this.$q.dialog({
        title: this.$t({ id: 'component.cards.account_form_card.dialog.title' }),
        message: this.$t({ id: 'component.cards.account_form_card.dialog.message' }),
        cancel: { label: this.$t({ id: 'prompt.cancel_button' }), size: 'md', color: 'dark' },
        ok: { label: this.$t({ id: 'prompt.delete_button' }), size: 'md', color: 'negative' },
      }).onOk(async () => {
        try {
          for (const k of deepKeys(this.stepData)) this.$_.set(this.stepData, k, null)
          await this.saveStep({ stepIncrement: 1 })
        } catch (e) { this.useLogger(e) }
      })
    },

    // FROM FORMSTEPCARD
    stepResult (event) {
      this.canGoNext = event.canGoNext
      this.stepData = event.value
      if (event.canGoNext && event.saveStep) this.saveStep({ stepIncrement: 1 })
    },
  },
}
</script>

<style lang="sass">
#sideBySideStepper
  .q-stepper__tab
    padding: 12px !important
  .q-stepper--vertical .q-stepper__step-inner
    padding: 0 24px 24px 48px !important
</style>
