<template>
  <QNoSsr v-if="props.content">
    <QMarkdown
      no-heading-anchor-links
      class="text-body2"
      :extend="extendMarkdown"
      :src="props.content"
    />
  </QNoSsr>
</template>

<script setup>
import { QMarkdown } from '@quasar/quasar-ui-qmarkdown'

const props = defineProps({
  content: {
    type: String,
    required: true
  }
})

function extendMarkdown (md) {
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const token = tokens[idx]
    const hrefIndex = token.attrIndex('href')
    if (token.attrs[hrefIndex][1][0] === '/') {
      token.attrSet('class', 'q-markdown--link q-markdown--link-local')
    } else {
      token.attrSet('target', '_blank')
      token.attrSet('class', 'q-markdown--link q-markdown--link-external')
    }
    return self.renderToken(tokens, idx, options)
  }
}
</script>
